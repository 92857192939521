<template>
    <div class='grid'>

        <DayOffListReport :report='report'></DayOffListReport>

        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid mb-3'>

                    <div class='col-12 lg:col-2'>
                        <h5 class='mb-0 mt-3'><i class='pi pi-history mr-2 ml-2'></i> Proje İzinli Personel Listesi</h5>
                    </div>

                    <div class='col-12 lg:col-10 text-right'>
                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true'
                                  :showButtonBar='true' v-model='selectedDate'
                                  @date-select='selectedDateChanged'></Calendar>
                    </div>

                </div>

                <DataTable :value='dayOffList' class='p-datatable-gridlines' :rows='50' dataKey='id' :rowHover='true'
                           :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                    <template #empty>
                        İzinli personel bulunamadı.
                    </template>

                    <template #loading>
                        Projedeki izin personeller yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                            </template>
                    </Column>

                     <Column field='employee.name' header='Ad'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column field='projectEmployee.department' header='Departman' style='width:12rem'>
                    </Column>

                    <Column field='startDate' header='Başlangıç Tarihi' style='width:10rem'>
                        <template #body='slotProps'>
                            <span class="text-green-600 font-bold">{{ formatDate(slotProps.data.startDate) }}</span>
                        </template>
                    </Column>

                    <Column field='endDate' header='Bitiş Tarihi' style='width:10rem'>
                        <template #body='slotProps'>
                            <span class="text-red-600 font-bold">{{ formatDate(slotProps.data.endDate) }}</span>
                        </template>
                    </Column>

                    <Column field='dayCount' header='Gün Sayısı' bodyClass='text-center' style='width:6rem'>
                    </Column>

                    <Column field='totalPrice' header='Kesilen Ücret' style='width:7rem'>
                        <template #body='slotProps'>
                            <span class="font-bold">{{ formatPrice(slotProps.data.totalPrice) }} &#8378;</span>
                        </template>
                    </Column>

                    <Column field='isPriceCut' header='Ücret Kesildi Mi?' style='width:7rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.isPriceCut == true ? 'Evet' : 'Hayır' }}
                        </template>
                    </Column>

                    <Column field='type' header='İzin Tipi' style='width:10rem'>
                    </Column>

                    <Column field='comment' header='Açıklama'>
                    </Column>
                    <Column header='Görüntüle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-search' class='p-button-rounded p-button-primary mr-2'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>
                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import store from '@/store';
import EmployeeDayOffService from '../../services/employeeDayOffService';
import DayOffListReport from '../../components/shared/DayOffReport';

export default {
    components: { DayOffListReport },
    data() {
        return {
            selectedDate: null,
            dayOffList: [],
            filters: null,
            loadingIndicator: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            report: {
                totalPersonCount:0,
                totalPriceCut:0,
                totalDayCount:0,
                totalItemCount:0
            }
        };
    },
    _employeeDayOffService: null,
    created() {
        this.selectedDate = moment().toDate();
        this.projectId = store.getters.getActiveProject.id;
        this._employeeDayOffService = new EmployeeDayOffService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        await this.getEmployeeDayOffList();
    },
    methods: {
        async selectedDateChanged() {
            await this.getEmployeeDayOffList();
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        edit(item) {
            this.$router.push({ name: 'dayOffProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.projectEmployeeId } });
        },
        async getEmployeeDayOffList() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let dayOffResponse = await this._employeeDayOffService.getProjectDayOffs(this.projectId, startDate, endDate);
            if (dayOffResponse.isSuccess) {
                this.dayOffList = dayOffResponse.data;
                await this.getEmployeeDayOffReport();

            }
            this.loadingIndicator = false;
        },

        async getEmployeeDayOffReport() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let dayOffResponse = await this._employeeDayOffService.getProjectDayOffReport(this.projectId, startDate, endDate);

            if (dayOffResponse.isSuccess) {
                this.report = dayOffResponse.data;
            }
            console.log(this.report);
            this.loadingIndicator = false;
        },
    },
};
</script>

